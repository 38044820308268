@import 'variables-start';
@import 'vars-frameworks';

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

@import "../../node_modules/slicknav/scss/slicknav";

//customs
@import 'fonts';
@import 'variables';
@import 'program';
@import 'variables-end';
@import 'html';
@import 'general';
@import 'header';
@import 'body';
@import 'main-div-body';
@import 'footer';
@import 'animations';
@import 'buttons-and-links';
@import 'sections';
@import 'lists';
@import 'slider-header';
@import 'menu-top';
@import 'slicknav';
@import 'carousels';
@import 'main-products';
@import 'forms';
@import 'masks';
@import 'jquery-validate';
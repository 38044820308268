.slicknav_menu {
  display: none;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  background-color: transparent;
  width: 100%;

  @media(max-width: 1200px) {
    display: block;
  }

  .slicknav_btn {
    background-color: transparent;
    padding: 8px 8px 4px 8px;
    .slicknav_icon {
      font-size: 24px;
      .slicknav_icon-bar {
        background-color: $main-primary-color;
      }
    }
	  margin-right: 20px;
  }

  .slicknav_nav {
    background-color: rgba(255, 255, 255, .9);
    padding-top: 0;
    padding-bottom: 0;
    position: absolute;
    top: 80px;
    left: 0px;
    right: 0px;
    width: 100%;
    & > li > a {
      font-size: 18px;
      line-height: normal;
      margin: 0 0;
      padding: 10px 10px 5px 10px;
      border-top: 1px solid #efefef;
      text-align: center;
    }

    a {
      line-height: normal;
      border-radius: 0;
      color: $main-primary-color;
      margin: 0;
      padding: 5px;
      text-decoration: none;
      text-align: left;

      &:hover, &:focus {
        background-color: $main-primary-color;
        color: #fff;
        text-decoration: none;
      }
    }

    .slicknav_row {
      color: $main-secondary-color;
      &:hover, &:focus {
        border-radius: 0;

        a {
          color: #fff;
        }

        .slicknav_arrow {
          color: #fff;
        }
      }

      .slicknav_arrow {
        color: $main-secondary-color;
      }
    }

    img.menu-image {
      display: none;
    }

    .menu-image-title {
      display: inline;
    }

  }

  .slicknav_icon-bar {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
}
.main-products {
	.item {
		text-align: center;
		margin-bottom: pixelsToRem(30);
		img {
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: pixelsToRem(15);
		}
		.h3 {
			@include addFontSizeLineHeightPxToRem(26, 24);
			color: $main-alternate-color;
			font-weight: 400;
			margin-bottom: pixelsToRem(60);
			margin-top: pixelsToRem(30);
		}
		.h4 {
			margin-bottom: pixelsToRem(60);
			margin-top: pixelsToRem(30);
			font-weight: 400;
		}
	}
}
@use "sass:math";

@function pixelsToRem($pixels) {
  $remValue: math.div($pixels, $main-base-font-size-int) + rem;
  @return $remValue;
}

@mixin pxToRemWithRwd($property, $pixels, $rwdSizePercent: 100) {
  #{$property}: pixelsToRem($pixels);
  @if $rwdSizePercent != 100 {
    @media(max-width: 767px) {
      #{$property}: math.div($pixels, $main-base-font-size-int) * ($rwdSizePercent/100) + rem;
    }
  }
}

@mixin addFontSizeLineHeightPxToRem($pxFontSize, $pxLineHeight: $main-base-line-height-int, $rwdSizePercent: 100) {
  font-size: pixelsToRem($pxFontSize);
  @if $pxLineHeight > 0 {
    line-height: math.div($pxLineHeight, $pxFontSize);
  }
  @if $rwdSizePercent != 100 {
    @media(max-width: 767px) {
      font-size: math.div($pxFontSize, $main-base-font-size-int) * ($rwdSizePercent/100) + rem;
    }
  }
}

@mixin addBackground($bgColor: transparent, $bgImage: none, $bgRepeat: no-repeat, $bgSize: cover, $bgPosition: center) {
  background-image: $bgImage;
  background-repeat: $bgRepeat;
  background-size: $bgSize;
  background-position: $bgPosition;
}
.main-btn-toggle {
	background-color: transparent;
	border: none;
	padding: none;
	color: $main-secondary-color;
}

.main-btn-link {
	display: block;
	color: $main-secondary-color;
	&:hover, &:focus {
		text-decoration: none;
	}
}

.main-btn-one {
	border: 1px solid $main-border-color;
	background-color: #fff;
	padding: pixelsToRem(10) pixelsToRem(40);
	color: $main-border-color;
	&:hover, &:focus {
		border-color: #fff;
		background-color: $main-primary-color;
		color: #fff;
	}
}
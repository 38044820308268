#carousel-one {
	height: 100%;
	
	.carousel-inner {
		height: 100%;
		@media(max-width: 767px) {
			padding-bottom: pixelsToRem(60);
		}
		
		.carousel-item {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			height: 100%;
			
			& > div {
				max-width: 480px;
				padding-left: pixelsToRem(60);
				padding-right: pixelsToRem(60);
				@media(max-width: 1000px) {
					padding-left: pixelsToRem(30);
					padding-right: pixelsToRem(30);
				}
				@media(max-width: 767px) {
					padding-left: 0;
					padding-right: 0;
				}

				&.carousel-caption {
					position: static;
					background-color: #fff;
					color: $main-body-text-color;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					@media(max-width: 767px) {
						padding-top: pixelsToRem(40);
						padding-bottom: pixelsToRem(40);
					}
					& > div {
						.h3 {
							color: #000;
							@include addFontSizeLineHeightPxToRem(30, 37);
							font-family: $main-alternate-font-family;
							font-weight: 400;
							margin-bottom: pixelsToRem(30);
							margin-top: pixelsToRem(60);
							@media(max-width: 1500px) {
								margin-bottom: pixelsToRem(15);
								margin-top: pixelsToRem(15);
							}
							@media(max-width: 1000px) {
								font-size: pixelsToRem(24);
							}
							@media(max-width: 767px) {
								margin-bottom: pixelsToRem(30);
								margin-top: pixelsToRem(30);
							}
						}
					}
				}
			}
			
		}
	}
	
	.carousel-indicators {
		margin-left: 0;
		margin-right: pixelsToRem(30);
		margin-bottom: pixelsToRem(60);
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		top: auto;
		bottom: 0;
		@media(max-width: 1500px) {
			margin-top: pixelsToRem(30);
			margin-bottom: pixelsToRem(10);
			padding-left: pixelsToRem(30);
			padding-right: pixelsToRem(30);
		}
		@media(max-width: 767px) {
			margin-bottom: pixelsToRem(40);
			margin-right: 0;
		}
		[data-bs-target] {
			width: 130px;
			height: 2px;
			margin-right: 5px;
			margin-left: 5px;
			border: 2px solid #fff;
			background-color: #fff;
			opacity: 1;
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
			@media(max-width: 1200px) {
				max-width: 30%;
			}
		}
		
		.active {
			background-color: $main-primary-color;
			width: 130px;
			height: 2px;
			@media(max-width: 1200px) {
				max-width: 30%;
			}
		}
		
		@media(max-width: 767px) {
			//display: none;
		}
	}
	
	.crl-next {
		position: absolute;
		z-index: 2;
		top: calc(50% - 33px);
		right: -33px;
		@media(max-width: 1000px) {
			display: none;
		}
	}
	
}
#menu-top {
	margin: 0;
	padding-left: 0;
	list-style-type: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: calc(100% - 364px);
	
	@media(max-width: 1200px) {
		display: none;
	}
	
	& > li {
		display: inline-block;
		& > a {
			text-shadow: 0px 0px 1.5px #FFFFFF;
			@include addFontSizeLineHeightPxToRem(16, 24);
			font-weight: 400;
			color: $main-primary-color;
			text-transform: uppercase;
			display: inline-block;
			margin-left: pixelsToRem(15);
			margin-right: pixelsToRem(15);
			text-decoration: none;
			&:hover, &:focus {
				text-decoration: none;
				color: $main-alternate-color;
			}
			&.active {
				color: #000;
				font-weight: 500;
				&:hover, &:focus {
					text-decoration: none;
					color: $main-alternate-color;
				}
			}
		}
	}
}
p {
	@include pxToRemWithRwd(margin-bottom, 10);
	&:last-child {
		margin-bottom: 0;
	}
}

a {
	text-decoration: none;
	&:hover, &:focus {
		text-decoration: underline;
	}
}

.main-primary-color-text {
	color: $main-primary-color !important;
}

.main-primary-color-bg {
	background-color: $main-primary-color !important;
}

.main-secondary-color-text {
	color: $main-secondary-color !important;
}

.main-secondary-color-bg {
	background-color: $main-secondary-color !important;
}


.container {
	max-width: $container-body-max-width;
}

.main-max-one {
	max-width: 740px;
}

.h2 {
	font-family: $main-alternate-font-family;
	@include addFontSizeLineHeightPxToRem(30, 37, 90);
	text-align: center;
	margin-bottom: pixelsToRem(30);
}
.h3 {
	color: #000;
	@include addFontSizeLineHeightPxToRem(30, 37);
	font-family: $main-alternate-font-family;
	font-weight: 400;
	margin-bottom: pixelsToRem(30);
	margin-top: pixelsToRem(60);
	@media(max-width: 1500px) {
		margin-bottom: pixelsToRem(15);
		margin-top: pixelsToRem(15);
	}
	@media(max-width: 1000px) {
		font-size: pixelsToRem(24);
	}
	@media(max-width: 767px) {
		margin-bottom: pixelsToRem(30);
		margin-top: pixelsToRem(30);
	}
}
.icon-item {
	text-align: center;
	margin-bottom: pixelsToRem(60);
	img {
		width: 64px;
		height: 64px;
	}
}
#main-logo-link {
	.main-logo {
		height: 57px;
		width: auto;
		margin: 12px 0;
		max-width: 100%;
		@media(max-width: 500px) {

		}
	}
}
.form-one {
	.form-group {
		margin-bottom: pixelsToRem(20);
		&:last-child {
			margin-bottom: 0;
		}
		.form-check {
			position: relative;
			padding-left: pixelsToRem(60);
			padding-right: pixelsToRem(60);
			@media(max-width: 767px) {
				padding-left: pixelsToRem(27);
				padding-right: 0;
			}
			input {
				border-radius: 0;
			}
			#data_reg-error {
				position: absolute;
				bottom: -8px;
				&+.form-check-label {
					margin-bottom: 10px;
				}
			}
		}
	}
	input[type="email"], input[type="text"], input[type="phone"], textarea {
		border: 1px solid $main-border-color;
	}
	textarea {
		min-height: 150px;
	}
}
form {
	label.error {
		color: red;
		font-size: 12px;
		line-height: normal;
		font-weight: 700;
		padding: 4px 0;
		display: block;
		margin-top: 4px;
		font-style: italic;
		@media(max-width: 767px) {
			font-size: 11px;
		}
		
		&#data_reg-error {
			margin-top: 30px !important;
		}
	}
}